import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProtectionPerson } from '../model/survey.model';

@Component({
  selector: 'protection-person',
  templateUrl: './protection-person.component.html'
})
export class ProtectionPersonComponent implements OnInit {
  @Input() protectionPerson?: boolean;
  @Input() minors?: boolean;

  @Output() complete = new EventEmitter<ProtectionPerson>();

  formGroup!: FormGroup;

  ngOnInit() {
    this.formGroup = new FormGroup({
      company: new FormControl(null),
      flagAdvice: new FormControl(null, this.getValidators(!this.protectionPerson)),
      
      dangerousJob: new FormControl(null, this.getValidators(this.protectionPerson)),
      unexpectedExpenses: new FormControl(null, this.getValidators(this.protectionPerson)),
      lifeTenor: new FormControl(null, this.getValidators(this.protectionPerson)),
      specialisticExaminations: new FormControl(null, this.getValidators(this.protectionPerson)),
      ssn: new FormControl(null, this.getValidators(this.protectionPerson)),
      minorChildren: new FormControl(null, this.getValidators(this.protectionPerson && this.minors))
    });
  }

  getValidators(required: boolean | undefined) {
    return required ? [Validators.required] : []
  }

  get company() { return this.formGroup.get('company'); }
  get flagAdvice() { return this.formGroup.get('flagAdvice'); }
  get dangerousJob() { return this.formGroup.get('dangerousJob'); }
  get unexpectedExpenses() { return this.formGroup.get('unexpectedExpenses'); }
  get lifeTenor() { return this.formGroup.get('lifeTenor'); }
  get specialisticExaminations() { return this.formGroup.get('specialisticExaminations'); }
  get ssn() { return this.formGroup.get('ssn'); }
  get minorChildren() { return this.formGroup.get('minorChildren'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  onSubmit() {
    if(!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let output: ProtectionPerson = {
      company: this.company?.value,
      flagAdvice: this.flagAdvice?.value,
      
      dangerousJob: this.dangerousJob?.value,
      unexpectedExpenses: this.unexpectedExpenses?.value,
      lifeTenor: this.lifeTenor?.value,
      specialisticExaminations: this.specialisticExaminations?.value,
      ssn: this.ssn?.value,
      minorChildren: this.minorChildren?.value,
    };

    this.formGroup.disable();
    this.complete.emit(output);
  }
}
