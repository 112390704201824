import { Observable, ReplaySubject, Subject } from "rxjs";

declare type GetDataHandler<T> = () => Observable<T>;

export class Cacheable<T> {
    protected data!: T | null;
    protected subjectData: Subject<T>;
    protected observableData: Observable<T>;
    public getHandler!: GetDataHandler<T>;

    constructor() {
        this.subjectData = new ReplaySubject(1);
        this.observableData = this.subjectData.asObservable();
    }

    public getData(): Observable<T> {
        if (!this.getHandler) {
            throw new Error("getHandler is not defined");
        }

        if (!this.data) {
            this.getHandler()
            .subscribe({
                next: (result: T) => {
                    this.data = result;
                    this.subjectData.next(result)
                },
                complete: () => {
                    this.subjectData.complete();
                },
                error: (err: any) => {
                    this.subjectData.error(err)
                }
            });
        }

        return this.observableData;
    }

    public resetCache(): void {
        this.data = null;
    }

    public refresh(): void {
        this.resetCache();
        this.getData();
    }
}