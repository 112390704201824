<h2 mat-dialog-title>{{ "survey.external.exit-dialog.title" | translate }}</h2>

<mat-dialog-content class="mat-typography">
    <p>{{ "survey.external.exit-dialog.body" | translate }}</p>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-end">
    <button mat-dialog-close class="btn btn-secondary mx-2">
        {{ "survey.external.exit-dialog.undo" | translate }}
    </button>
    <button class="btn btn-primary" (click)="doConfirm()">
        {{ "survey.external.exit-dialog.confirm" | translate }}
    </button>
</mat-dialog-actions>
