<div class="container my-3">
    <div *ngIf="formGroup" [formGroup]="formGroup">
        <!-- PROTECTION HOUSE-->
        <div *ngIf="protectionHouse">
            <div class="text-secondary fst-italic">{{ "survey.new.protection-goods.house.text-1" | translate }}.</div>
        
            <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

            <!-- TYPE -->
            <div class="form-group row">
                <label for="houseType" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.type" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="houseType" formControlName="houseType">
                        <option value=""></option>
                        <option *ngFor="let key of ['CA1', 'CA2', 'CA3']" [value]="key">
                            {{ "survey.domain.protection-goods.house-type." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(houseType)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- DIMENSION -->
            <div class="form-group row">
                <label for="houseDimension" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.dimension" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="houseDimension" formControlName="houseDimension">
                        <option value=""></option>
                        <option *ngFor="let key of ['DC1', 'DC2', 'DC3', 'DC4', 'DC5']" [value]="key">
                            {{ "survey.domain.protection-goods.house-dimension." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(houseDimension)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
        
            <!-- VALUE -->
            <div class="form-group row">
                <label for="houseValue" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.value" | translate }}</label>
                <div class="col-6">
                    <input prefix="€ " mask="separator.0" thousandSeparator="." type="text" class="form-control" id="houseValue" formControlName="houseValue">
                </div>
            </div>

            <!-- PROTECTION -->
            <div class="form-group row">
                <label for="houseProtection" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.protection" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="houseProtection" formControlName="houseProtection">
                        <option value=""></option>
                        <option *ngFor="let key of ['PC1', 'PC2']" [value]="key">
                            {{ "survey.domain.protection-goods.house-protection." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(houseProtection)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- OTHER PROPERTIES -->
            <div class="form-group row">
                <label for="otherProperties" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.other-properties" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="otherProperties" formControlName="otherProperties">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(otherProperties)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- OTHER PROPERTIES USE -->
            <div class="form-group row" @inOut *ngIf="otherProperties?.value === 'Y'">
                <label for="houseUse" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.other-use" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="houseUse" formControlName="houseUse">
                        <option value=""></option>
                        <option *ngFor="let key of ['UC1', 'UC2']" [value]="key">
                            {{ "survey.domain.protection-goods.other-use." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(houseUse)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <div *ngIf="protectionHouseSeismic" class="mt-5">
                <div class="text-secondary fst-italic">{{ "survey.new.protection-goods.house.sismology-text-1" | translate }}.</div>
                <div class="text-secondary fst-italic">{{ "survey.new.protection-goods.house.sismology-text-2" | translate: { class: seismicClass, percentage: seismicityValue} }}.</div>

                <!-- HOUSE SISMOLOGY -->
                <div class="form-group row mt-3">
                    <label for="houseSismology" class="col-6 col-form-label">{{ "survey.new.protection-goods.house.sismology" | translate }}</label>
                    <div class="col-6">
                        <select class="form-select" id="houseSismology" formControlName="houseSismology">
                            <option value=""></option>
                            <option *ngFor="let key of ['SC1', 'SC2', 'SC3']" [value]="key">
                                {{ "survey.domain.protection-goods.house-sismology." + key | translate }}
                            </option>
                        </select>
                        <mat-error *ngIf="hasError(houseSismology)">
                            {{ "validation.required" | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <!-- NOT PROTECTION HOUSE-->
        <div *ngIf="!protectionHouse">
            <div class="text-secondary fst-italic">{{ "survey.new.protection-goods.house-not.text-1" | translate }}.</div>
            <div class="text-secondary fst-italic">{{ "survey.new.protection-goods.house-not.text-2" | translate }}.</div>
        
            <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

            <!-- COMPANY -->
            <div class="form-group row">
                <label for="company" class="col-6 col-form-label">{{ "survey.new.protection-goods.house-not.company" | translate }}</label>
                <div class="col-6">
                    <input class="form-control" id="company" formControlName="company">
                    <mat-error *ngIf="hasError(company)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- FLAG ADVICE -->
            <div class="form-group row">
                <label for="flagAdvice" class="col-6 col-form-label">{{ "survey.new.protection-goods.house-not.flag-advice" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="flagAdvice" formControlName="flagAdvice">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(flagAdvice)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- NEXT -->
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="onSubmit()">
                {{ "survey.new.protection-goods.next" | translate }}
            </button>
        </div>
    </div>
</div>