export const environment = {
    production: false,
    title: 'Consensus Test',

    api: {
        domain: '/api/domain',
        auth: '/api/authentication',
        ndg: '/api/ndg',
        survey: '/api/survey'
    }
}