import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { City } from '../model/property';
import { Cacheable } from '../util/cacheable.service';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  private cityListCache: Cacheable<City[]> = new Cacheable<City[]>();

  constructor(
    private http: HttpClient
  ) {
    this.cityListCache.getHandler = () => {
      return this.http.get<City[]>(environment.api.domain + '/city');
    }
  }

  getCityList(): Observable<City[]> {
    return this.cityListCache.getData();
  }
}
