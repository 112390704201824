import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'src/app/service/message.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { SurveyService } from '../../service/survey.service';
import { FinancialKnowledgeQuestionResponse, SavingsInvestments, FinancialKnowledgeQuestion } from '../model/survey.model';

@Component({
  selector: 'savings-investments',
  templateUrl: './savings-investments.component.html',
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms ease-in',
          style({ opacity: 1, display: 'contents' })
        )
      ]),
      transition('* => void', [
        animate('500ms ease-out',
          style({ opacity: 0 })
        ) 
      ])
    ]) 
  ]
})
export class SavingsInvestmentsComponent implements OnInit {
  @Output() complete = new EventEmitter<SavingsInvestments>();

  formGroup!: FormGroup;
  defaultKnokledgeClass: number = 0; //FIXME legacy non è impostata
  financialKnowledgeQuestionList: FinancialKnowledgeQuestionResponse[] = [];

  constructor(
    private elementRef: ElementRef,
    private spinnerService: SpinnerService,
    private messageService: MessageService,
    private surveyService: SurveyService,
  ) {}

  ngOnInit() {
    this.spinnerService.showProgress(this.elementRef);

    this.surveyService.getRandomFinancialKnowledgeQuestions().subscribe({
      next: (result: FinancialKnowledgeQuestionResponse[]) => {
        this.financialKnowledgeQuestionList = result;

        this.initForm();
        this.spinnerService.detach(this.elementRef);
      },
      error: () => {
        this.spinnerService.detach(this.elementRef);
        this.messageService.error("survey.new.message.unknown-error");
      }
    })
  }

  private initForm() {
    this.formGroup = new FormGroup({
      duration: new FormControl(null, Validators.required),
      thirdYearRevenue: new FormControl(0, Validators.required),
      maxLoss: new FormControl(0, Validators.required),

      skipFinancialKnowledge: new FormControl(true, Validators.required),

      stocks: new FormControl(null, Validators.required),
      bonds: new FormControl(null, Validators.required),
      investmentFunds: new FormControl(null, Validators.required),
      financialPolicies: new FormControl(null, Validators.required),
      assetManagement: new FormControl(null, Validators.required),
      derivates: new FormControl(null, Validators.required),
      operationNumber: new FormControl(null, Validators.required)
    });

    this.skipFinancialKnowledge?.valueChanges.subscribe({
      next: (skip: boolean) => {
        if(skip) {
          this.financialKnowledgeQuestionList.forEach(question => {
            this.formGroup.removeControl(question.id);
          });
        } else {
          this.financialKnowledgeQuestionList.forEach(question => {
            this.formGroup.addControl(question.id, new FormControl(null, Validators.required));
          });
        }
      }
    });
  }

  get duration() { return this.formGroup.get('duration'); }
  get thirdYearRevenue() { return this.formGroup.get('thirdYearRevenue'); }
  get maxLoss() { return this.formGroup.get('maxLoss'); }
  get skipFinancialKnowledge() { return this.formGroup.get('skipFinancialKnowledge'); }
  get stocks() { return this.formGroup.get('stocks'); }
  get bonds() { return this.formGroup.get('bonds'); }
  get investmentFunds() { return this.formGroup.get('investmentFunds'); }
  get financialPolicies() { return this.formGroup.get('financialPolicies'); }
  get assetManagement() { return this.formGroup.get('assetManagement'); }
  get derivates() { return this.formGroup.get('derivates'); }
  get operationNumber() { return this.formGroup.get('operationNumber'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  hasQuestionError(questionId: string) {
    let field = this.formGroup.get(questionId);
    return this.hasError(field);
  }

  onSubmit() {
    if(!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.formGroup.disable();

    let financialKnowledge = this.financialKnowledgeQuestionList.map(question => {
      let request: FinancialKnowledgeQuestion = {
        id: question.id,
        answer: this.formGroup.get(question.id)?.value
      };

      return request;
    });

    let out: SavingsInvestments = {
      investmentDuration: this.duration?.value,
      thirdYearRevenue: this.thirdYearRevenue?.value,
      maxLoss: this.maxLoss?.value,
      
      skipFinancialKnowledge: this.skipFinancialKnowledge?.value,
      financialKnowledge: financialKnowledge,
      
      stocks: this.stocks?.value,
      bonds: this.bonds?.value,
      investmentFunds: this.investmentFunds?.value,
      financialPolicies: this.financialPolicies?.value,
      assetManagement: this.assetManagement?.value,
      derivates: this.derivates?.value,
      operationNumber: this.operationNumber?.value
    };

    this.complete.emit(out);
  }
}
