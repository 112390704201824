import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './confirm-exit-dialog.component.html'
})
export class ConfirmExitDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<ConfirmExitDialogComponent>,
  ) {}

  doConfirm() {
    this.dialogRef.close(true);
  }
}
