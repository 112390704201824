import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { MessageService } from '../service/message.service';
import { AuthService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private messageService: MessageService,
    private authService: AuthService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse, caught: Observable<HttpEvent<unknown>>) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          this.authService.logout();
          location.reload();

          this.messageService.error('messages.not-authorized');
          throw 'messages.not-authorized';
      }

      if ([0, 404, 500, 503].indexOf(err.status) !== -1) {
          this.messageService.error('messages.network-error');
          throw 'messages.network-error';
      }

      if ([400].indexOf(err.status) !== -1) {
          console.error('Bad request', err.error);
          throw err;
      }

      const error = err.error.message || err.statusText;
      throw error;
      })
    );
  }
}
