import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private config: MatSnackBarConfig;

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.config = new MatSnackBarConfig();

    this.config.verticalPosition = 'top';
    this.config.horizontalPosition = 'center';
  }

  public success(message: string, parameters?: Object): void {
    this.config.panelClass = 'success-snackbar';
    this.config.duration = 3000;
    this.open(message, parameters || {});
  }

  public error(message: string, parameters?: Object): void {
    this.config.panelClass = ['error-snackbar'];
    this.config.duration = 5000;
    this.open(message, parameters || {});
  }

  private open(message: string, parameters: Object): void {
    this.snackBar.open(this.translateService.instant(message, parameters), undefined, this.config);
  }
}
