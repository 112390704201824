<div class="container my-3">
    <div class="text-secondary fst-italic">{{ "survey.new.ndg-data.text-1" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.ndg-data.text-2" | translate }}.</div>

    <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

    <div *ngIf="ndgForm" [formGroup]="ndgForm">
        <div class="form-group row mt-4">
            <label for="type" class="col-4 col-form-label">{{ "survey.new.ndg-data.type" | translate }}</label>
            <div class="col-8">
                <select class="form-control" id="type" formControlName="type">
                    <option *ngFor="let key of ['FIS', 'GIU']" [value]="key">
                        {{ "survey.domain.ndg.type." + key | translate }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="name" class="col-4 col-form-label">{{ "survey.new.ndg-data.name" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="name" [value]="fullName" disabled>
            </div>
        </div>

        <div class="form-group row">
            <label for="address" class="col-4 col-form-label">{{ "survey.new.ndg-data.address" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="address" [value]="completeAddress" disabled>
            </div>
        </div>

        <div class="form-group row">
            <label for="dateOfBirth" class="col-4 col-form-label">{{ "survey.new.ndg-data.birth-date" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="dateOfBirth" [value]="ndg.dateOfBirth" disabled>
            </div>
        </div>

        <div class="form-group row">
            <label for="gender" class="col-4 col-form-label">{{ "survey.new.ndg-data.gender" | translate }}</label>
            <div class="col-8">
                <select class="form-control" id="gender" formControlName="gender">
                    <option *ngFor="let key of ['M', 'F']" [value]="key">
                        {{ "survey.domain.ndg.gender." + key | translate }}
                    </option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label for="occupation" class="col-4 col-form-label">{{ "survey.new.ndg-data.occupation" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="occupation" formControlName="occupation">
                    <option value=""></option>
                    <option *ngFor="let key of ['CAS', 'STU', 'OPE', 'IMP', 'ENT', 'LPR', 'DIR', 'RIT', 'ALT']" [value]="key">
                        {{ "survey.domain.ndg.occupation." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(occupation)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="graduation" class="col-4 col-form-label">{{ "survey.new.ndg-data.graduation" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="graduation" formControlName="graduation">
                    <option value=""></option>
                    <option *ngFor="let key of ['ELE', 'MED', 'DIP', 'LAU']" [value]="key">
                        {{ "survey.domain.ndg.graduation." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(graduation)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="familySize" class="col-4 col-form-label">{{ "survey.new.ndg-data.family-size" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="familySize" formControlName="familySize">
                    <option value=""></option>
                    <option *ngFor="let key of ['FC1', 'FC2', 'FC3', 'FC4', 'FC5']" [value]="key">
                        {{ "survey.domain.ndg.family-size." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(familySize)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="maritalStatus" class="col-4 col-form-label">{{ "survey.new.ndg-data.marital-status" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="maritalStatus" formControlName="maritalStatus">
                    <option value=""></option>
                    <option *ngFor="let key of ['SC1', 'SC2', 'SC3', 'SC4', 'SC5', 'SC6']" [value]="key">
                        {{ "survey.domain.ndg.marital-status." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(maritalStatus)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>
        
        <div class="form-group row">
            <label for="heritageTot" class="col-4 col-form-label">{{ "survey.new.ndg-data.heritage-tot" | translate }}</label>
            <div class="col-8">
                <input prefix="€ " mask="separator.0" thousandSeparator="." type="text" class="form-control" id="heritageTot" formControlName="heritageTot">
            </div>
        </div>

        <div class="form-group row">
            <label for="heritageFinancial" class="col-4 col-form-label">{{ "survey.new.ndg-data.heritage-financial" | translate }}</label>
            <div class="col-8">
                <input prefix="€ " mask="separator.0" thousandSeparator="." type="text" class="form-control" id="heritageFinancial" formControlName="heritageFinancial">
            </div>
        </div>

        <div class="form-group row">
            <label for="averageIncome" class="col-4 col-form-label">{{ "survey.new.ndg-data.average-income" | translate }}</label>
            <div class="col-8">
                <input prefix="€ " mask="separator.0" thousandSeparator="." type="text" class="form-control" id="averageIncome" formControlName="averageIncome">
            </div>
        </div>

        <div class="form-group row">
            <label for="annualExpenses" class="col-4 col-form-label">{{ "survey.new.ndg-data.annual-expenses" | translate }}</label>
            <div class="col-8">
                <input prefix="€ " mask="separator.0" thousandSeparator="." type="text" class="form-control" id="annualExpenses" formControlName="annualExpenses">
            </div>
        </div>

        <div class="form-group row">
            <label for="sport" class="col-4 col-form-label">{{ "survey.new.ndg-data.sport" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="sport" formControlName="sport">
                    <option value=""></option>
                    <option *ngFor="let key of ['A', 'B', 'C']" [value]="key">
                        {{ "survey.domain.ndg.sport." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(sport)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="collaborator" class="col-4 col-form-label">{{ "survey.new.ndg-data.collaborator" | translate }}</label>
            <div class="col-8">
                <select class="form-select" id="collaborator" formControlName="collaborator">
                    <option value=""></option>
                    <option *ngFor="let key of ['Y', 'N']" [value]="key">
                        {{ "survey.domain.optional." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(collaborator)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="ndgForm.disabled" (click)="onSubmit()">
                {{ "survey.new.ndg-data.next" | translate }}
            </button>
        </div>
    </div>
</div>
