import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from './survey.component';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NewModule } from './new/new.module';
import { ExternalComponent } from './external/external.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ConfirmExitDialogComponent } from './external/confirm-exit-dialog/confirm-exit-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    SurveyComponent,
    ExternalComponent,
    ConfirmExitDialogComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,
    FontAwesomeModule,

    MatTooltipModule,
    MatToolbarModule,
    MatDialogModule,
    MatButtonModule,

    NewModule
  ]
})
export class SurveyModule { }
