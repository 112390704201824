export class SurveyPart1Request {
    ndgData!: NdgData;
    bisBas!: BisBas;
}

export interface NdgData {
    ndg: string;
    occupation: string;
    graduation: string;
    familySize: string;
    maritalStatus: string;
    sport: string;
    collaborator: string;

    heritageTot: number;
    heritageFinancial: number;
    averageIncome: number;
    annualExpenses: number;
}

export interface BisBas {
    skipBisBas: boolean;
    
    bad1: string;
    bad2: string;
    bad3: string;
    bad4: string;
    baf1: string;
    baf2: string;
    baf3: string;
    baf4: string;
    bar1: string;
    bar2: string;
    bar3: string;
    bar4: string;
    bar5: string;
    bis1: string;
    bis2: string;
    bis3: string;
    bis4: string;
    bis5: string;
    bis6: string;
    bis7: string;
}

export interface SurveyPart1ChecksResponse {
    surveyId: string;
    investSavings: boolean;
    invest: boolean;
    savingValue: string;
    protectionHouse: boolean;
    protectionHouse2: boolean;
    protectionHouseSeismic: boolean;
    seismicityValue: string;
    seismicClass: string;
    protectionPerson: boolean;
    minors: boolean;
}

export interface FinancialKnowledgeQuestionResponse {
    id: string;
    text: string;
    answers: FinancialKnowledgeAnswer[];
}

export interface FinancialKnowledgeAnswer {
    id: string;
    text: string;
}

export class SurveyPart2Request {
    savingsInvestments?: SavingsInvestments;
    mcq?: MCQ;
    protectionGoods?: ProtectionGoods;
    protectionPerson?: ProtectionPerson;
}

export interface SavingsInvestments {
    investmentDuration: number;
    thirdYearRevenue: number;
    maxLoss: number;
    
    skipFinancialKnowledge: boolean;
    financialKnowledge: FinancialKnowledgeQuestion[];
    
    stocks: string;
    bonds: string;
    investmentFunds: string;
    financialPolicies: string;
    assetManagement: string;
    derivates: string;
    operationNumber: string;
}

export interface FinancialKnowledgeQuestion {
    id: string;
    answer: string;
}

export interface MCQ {
    skipMcq: boolean;
	    
    mcq01: string;
    mcq02: string;
    mcq03: string;
    mcq04: string;
    mcq05: string;
    mcq06: string;
    mcq07: string;
    mcq08: string;
    mcq09: string;
    mcq10: string;
    mcq11: string;
    mcq12: string;
    mcq13: string;
    mcq14: string;
    mcq15: string;

    lot01: string;
    lot02: string;
    lot03: string;
    lot04: string;
    lot05: string;
    lot06: string;
    lot07: string;
    lot08: string;
    lot09: string;
    lot10: string;
}

export interface ProtectionGoods {
    company: string;
    flagAdvice: string;
    
    houseType: string;
    houseDimension: string;
    houseValue: number;
    houseProtection: string;
    otherProperties: string;
    houseUse: string;
    houseSismology: string;
}

export interface ProtectionPerson {
    company: string;
    flagAdvice: string;
    
    dangerousJob: string;
    unexpectedExpenses: string;
    lifeTenor: string;
    specialisticExaminations: string;
    ssn: string;
    minorChildren: string;
}

export interface SurveyForListResponse {
    surveyId: string;
    insertTime: string;
}
