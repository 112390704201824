import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NdgResponse } from 'src/app/model/ndg';
import { NdgData } from '../model/survey.model';

@Component({
  selector: 'ndg-data',
  templateUrl: './ndg-data.component.html'
})
export class NdgDataComponent {
  @Input() ndg!: NdgResponse;
  @Output() complete = new EventEmitter<NdgData>();

  ndgForm!: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.ndgForm = new FormGroup({
      type: new FormControl({value: this.ndg.typology, disabled: true}),
      gender: new FormControl({value: this.ndg.gender, disabled: true}),

      occupation: new FormControl(null, Validators.required),
      graduation: new FormControl(null, Validators.required),
      familySize: new FormControl(null, Validators.required),
      maritalStatus: new FormControl(null, Validators.required),
      heritageTot: new FormControl(0, Validators.required),
      heritageFinancial: new FormControl(0, Validators.required),
      averageIncome: new FormControl(0, Validators.required),
      annualExpenses: new FormControl(0, Validators.required),
      sport: new FormControl(null, Validators.required),
      collaborator: new FormControl(null, Validators.required)
    });
  }

  get completeAddress() {
    return this.ndg.address + ', ' + this.ndg.postalCode + ', ' + this.ndg.cityDescription + ' (' + this.ndg.districtId + ')';
  }

  get fullName() {
    return this.ndg.name + " " + this.ndg.surname;
  }

  get type() { return this.ndgForm.get('type'); }
  get gender() { return this.ndgForm.get('gender'); }
  
  get occupation() { return this.ndgForm.get('occupation'); }
  get graduation() { return this.ndgForm.get('graduation'); }
  get familySize() { return this.ndgForm.get('familySize'); }
  get maritalStatus() { return this.ndgForm.get('maritalStatus'); }
  get heritageTot() { return this.ndgForm.get('heritageTot'); }
  get heritageFinancial() { return this.ndgForm.get('heritageFinancial'); }
  get averageIncome() { return this.ndgForm.get('averageIncome'); }
  get annualExpenses() { return this.ndgForm.get('annualExpenses'); }
  get sport() { return this.ndgForm.get('sport'); }
  get collaborator() { return this.ndgForm.get('collaborator'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  onSubmit() {
    if(!this.ndgForm.valid) {
      this.ndgForm.markAllAsTouched();
      return;
    }

    this.ndgForm.disable();

    let out: NdgData = {
      ndg: this.ndg.id,
      occupation: this.occupation?.value,
      graduation: this.graduation?.value,
      familySize: this.familySize?.value,
      maritalStatus: this.maritalStatus?.value,
      sport: this.sport?.value,
      collaborator: this.collaborator?.value,
  
      heritageTot: this.heritageTot?.value,
      heritageFinancial: this.heritageFinancial?.value,
      averageIncome: this.averageIncome?.value,
      annualExpenses: this.annualExpenses?.value,
    };

    this.complete.emit(out);
  }
}
