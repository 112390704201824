<form [formGroup]="formGroup" autocomplete="off">
    <mat-tab-group mat-stretch-tabs="false" [selectedIndex]="selectedTab" [preserveContent]="true" (selectedTabChange)="onSelectedTabChange($event)">
        <mat-tab *ngIf="showAnagraphicTab" label="{{ 'survey.new.anagraphic-data.title' | translate }}">
            <anagraphic-data [ndg]="ndg" (complete)="onAnagraphicDataComplete($event)"></anagraphic-data>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.ndg-data.title' | translate }}" [disabled]="!ndg.name">
            <ndg-data [ndg]="ndg" (complete)="onNdgDataComplete($event)"></ndg-data>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.bis-bas.title' | translate }}" [disabled]="true">
            <bis-bas (complete)="onBisBasComplete($event)"></bis-bas>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.savings-investments.title' | translate }}" *ngIf="surveyPart1Response?.investSavings" [disabled]="true">
            <savings-investments (complete)="onSavingsInvestmentsComplete($event)"></savings-investments>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.mcq.title' | translate }}" *ngIf="surveyPart1Response?.investSavings && surveyPart1Response?.invest" [disabled]="true">
            <mcq (complete)="onMCQComplete($event)"></mcq>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.savings.title' | translate }}" *ngIf="surveyPart1Response?.investSavings && !surveyPart1Response?.invest" [disabled]="true">
            <savings [value]="surveyPart1Response?.savingValue" (complete)="onSavingsComplete()"></savings>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.protection-goods.title' | translate }}" [disabled]="true" *ngIf="surveyPart1Response">
            <protection-goods [protectionHouse]="surveyPart1Response.protectionHouse" 
                        [protectionHouseSeismic]="surveyPart1Response.protectionHouseSeismic"
                        [seismicClass]="surveyPart1Response.seismicClass"
                        [seismicityValue]="surveyPart1Response.seismicityValue"
                        (complete)="onProtectionGoodsComplete($event)">
            </protection-goods>
        </mat-tab>

        <mat-tab label="{{ 'survey.new.protection-person.title' | translate }}" [disabled]="true" *ngIf="surveyPart1Response">
            <protection-person [minors]="surveyPart1Response.minors"
                        [protectionPerson]="surveyPart1Response.protectionPerson"
                        (complete)="onProtectionPersonComplete($event)">
            </protection-person>
        </mat-tab>
        
        <!--mat-tab label="{{ 'survey.new.final.title' | translate }}" [disabled]="true">
            <final *ngIf="surveyId" [surveyId]="surveyId" (completed)="onSurveyCompleted($event)"></final>
        </mat-tab-->
    </mat-tab-group>
</form>
