<div class="container my-3">
	<form *ngIf="formGroup" [formGroup]="formGroup" autocomplete="off" (ngSubmit)="onSubmit()">
        <div class="form-group row mt-4">
            <label for="email" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.email" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="email" formControlName="email">
                <mat-error *ngIf="hasError(email)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="name" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.name" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="name" formControlName="name">
                <mat-error *ngIf="hasError(name)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="surname" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.surname" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="surname" formControlName="surname">
                <mat-error *ngIf="hasError(surname)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="fiscalCode" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.fiscal-code" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="fiscalCode" formControlName="fiscalCode" uppercase>
                <mat-error *ngIf="hasError(fiscalCode)">
                	{{ 'validation.' + getError(fiscalCode) | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="address" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.address" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="address" formControlName="address">
                <mat-error *ngIf="hasError(address)">
                    {{ 'validation.' + getError(address) | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="city" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.city" | translate }}</label>
            <div class="col-8">
                <input class="form-control" formControlName="city" id="city" autocomplete="off"
                                [ngbTypeahead]="citySearch" [inputFormatter]="cityFormatter" [resultFormatter]="cityFormatter"
                                (selectItem)="onCitySelected($event.item)" />
                <mat-error *ngIf="hasError(city)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="postalCode" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.postal-code" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="postalCode" formControlName="postalCode">
                <mat-error *ngIf="hasError(postalCode)">
                    {{ 'validation.required' | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-group row">
            <label for="phone" class="col-4 col-form-label">{{ "survey.new.anagraphic-data.phone" | translate }}</label>
            <div class="col-8">
                <input class="form-control" id="phone" formControlName="phone">
                <mat-error *ngIf="hasError(phone)">
                    {{ 'validation.required' | translate }}
                </mat-error>
            </div>
        </div>
        
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="completed">
                {{ 'survey.new.anagraphic-data.next' | translate }}
            </button>
        </div>
    </form>
</div>
