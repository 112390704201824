import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NdgRequest, NdgResponse } from '../model/ndg';

@Injectable({
  providedIn: 'root'
})
export class NdgService {
  constructor(
    private http: HttpClient
  ) {}

  getNdg(ndgId: string): Observable<NdgResponse> {
    return this.http.get<NdgResponse>(environment.api.ndg + '/' + ndgId);
  }

  updateNdg(ndgId: string, ndg: NdgRequest): Observable<NdgResponse> {
    return this.http.patch<NdgResponse>(environment.api.ndg + '/' + ndgId, ndg);
  }
}
