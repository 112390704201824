<p *ngIf="!ndg">{{ "survey.external.welcome-message" | translate }}</p>

<div *ngIf="!!ndg">
    <mat-toolbar>
        <div class="logo">
            <img src="assets/img/logo.png" />
        </div>

        <span class="spacer"></span>

        <fa-icon
                [icon]="['far', 'times-circle']"
                matTooltip="{{ 'survey.external.exit' | translate }}"
                class="clickable"
                (click)="doOpenExitDialog()"
        ></fa-icon>
    </mat-toolbar>
    <new-survey *ngIf="ndg" [ndg]="ndg" [external]="true" (completed)="onSurveyCompleted($event)"></new-survey>
</div>
