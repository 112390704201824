import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[uppercase]'
})
export class UppercaseDirective {
  constructor(
    private _el: ElementRef
  ) {}

  @HostListener('input')
  onInputChange() {
    this._el.nativeElement.value = this._el.nativeElement.value.toUpperCase();
  }
}
