import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NdgResponse } from 'src/app/model/ndg';
import { AuthService } from 'src/app/service/auth.service';
import { Auth } from 'src/app/model/auth';
import { MessageService } from 'src/app/service/message.service';
import { NdgService } from 'src/app/service/ndg.service';
import { SurveyForListResponse } from '../new/model/survey.model';
import { ConfirmExitDialogComponent } from './confirm-exit-dialog/confirm-exit-dialog.component';

@Component({
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class ExternalComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  ndg!: NdgResponse;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ndgService: NdgService,
    private messageService: MessageService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscription = this.route.queryParams.subscribe({
      next: (params: {[key: string]: string}) => {
        this.authService.nextToken(params['token']);

        this.authService.authenticateExternal(params['email']).subscribe({
          next: (auth: Auth) => {
            // Authentication successful
            this.getNdg(auth.username);
          },
          error: () => {
            // Authentication failed
            this.doManageError();
          }
        });
      }
    });
  }

  private getNdg(ndgId: string) {
    this.ndgService.getNdg(ndgId).subscribe({
      next: (ndg: NdgResponse) => {
        this.ndg = ndg;
      },
      error: () => {
        this.doManageError();
      }
    });
  }

  private doManageError() {
    this.messageService.error('survey.external.authentication-failed');
    window.parent.postMessage(null, '*');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onSurveyCompleted(survey: SurveyForListResponse): void {
    window.parent.postMessage(survey.surveyId, '*');
  }

  doOpenExitDialog() {
    const dialogRef = this.dialog.open(ConfirmExitDialogComponent, {
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe({
      next: (result: boolean) => {
        if(result) {
          window.parent.postMessage(null, '*');
        }
      }
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeHandler() {
    this.doOpenExitDialog();
  }
}
