<div class="container my-3">
    <div *ngIf="formGroup" [formGroup]="formGroup">
        <!-- PROTECTION PERSON-->
        <div *ngIf="protectionPerson">
            <div class="text-secondary fst-italic">{{ "survey.new.protection-person.person.text-1" | translate }}.</div>
        
            <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

            <!-- DANGEROUS JOB -->
            <div class="form-group row">
                <label for="dangerousJob" class="col-6 col-form-label">{{ "survey.new.protection-person.person.dangerous-job" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="dangerousJob" formControlName="dangerousJob">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(dangerousJob)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- UNEXPECTED EXPENSES -->
            <div class="form-group row">
                <label for="unexpectedExpenses" class="col-6 col-form-label">{{ "survey.new.protection-person.person.unexpected-expenses" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="unexpectedExpenses" formControlName="unexpectedExpenses">
                        <option value=""></option>
                        <option *ngFor="let key of ['SI1', 'SI2', 'SI3']" [value]="key">
                            {{ "survey.domain.protection-person.unexpected-expenses." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(unexpectedExpenses)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- LIFE TENOR -->
            <div class="form-group row">
                <label for="lifeTenor" class="col-6 col-form-label">{{ "survey.new.protection-person.person.life-tenor" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="lifeTenor" formControlName="lifeTenor">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(lifeTenor)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- SPECIALISTIC EXAMINATIONS -->
            <div class="form-group row">
                <label for="specialisticExaminations" class="col-6 col-form-label">{{ "survey.new.protection-person.person.specialistic-examinations" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="specialisticExaminations" formControlName="specialisticExaminations">
                        <option value=""></option>
                        <option *ngFor="let key of ['VS1', 'VS2', 'VS3']" [value]="key">
                            {{ "survey.domain.protection-person.specialistic-examinations." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(specialisticExaminations)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- SSN -->
            <div class="form-group row">
                <label for="ssn" class="col-6 col-form-label">{{ "survey.new.protection-person.person.ssn" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="ssn" formControlName="ssn">
                        <option value=""></option>
                        <option *ngFor="let key of ['SS1', 'SS2', 'SS3']" [value]="key">
                            {{ "survey.domain.protection-person.ssn." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(ssn)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- MINORS -->
            <div *ngIf="minors" class="form-group row">
                <label for="minorChildren" class="col-6 col-form-label">{{ "survey.new.protection-person.person.minor-children" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="minorChildren" formControlName="minorChildren">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(minorChildren)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- PROTECTION PERSON-->
        <div *ngIf="!protectionPerson">
            <div class="text-secondary fst-italic">{{ "survey.new.protection-person.person-not.text-1" | translate }}.</div>
            <div class="text-secondary fst-italic">{{ "survey.new.protection-person.person-not.text-2" | translate }}.</div>
        
            <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

            <!-- COMPANY -->
            <div class="form-group row">
                <label for="company" class="col-6 col-form-label">{{ "survey.new.protection-person.person-not.company" | translate }}</label>
                <div class="col-6">
                    <input class="form-control" id="company" formControlName="company">
                    <mat-error *ngIf="hasError(company)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- FLAG ADVICE -->
            <div class="form-group row">
                <label for="flagAdvice" class="col-6 col-form-label">{{ "survey.new.protection-person.person-not.flag-advice" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="flagAdvice" formControlName="flagAdvice">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(flagAdvice)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

        </div>

        <!-- NEXT -->
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="onSubmit()">
                {{ "survey.new.protection-person.complete" | translate }}
            </button>
        </div>
    </div>
</div>
