<div class="container my-3">
    <div class="text-secondary fst-italic">{{ "survey.new.mcq.text-1" | translate }}.</div>

    <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

    <div *ngIf="formGroup" [formGroup]="formGroup">

        <!-- SKIP MCQ TEST -->
        <div>{{ "survey.new.mcq.skip-title" | translate }}:</div>

        <div class="form-check">
            <input class="form-check-input" type="radio" [value]="true" formControlName="skipMcq" id="mcq-skip">
            <label class="form-check-label" for="mcq-skip">
                {{ "survey.new.mcq.skip" | translate: { level: defaultMcqClass } }}
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" [value]="false" formControlName="skipMcq" id="mcq-skip-not">
            <label class="form-check-label" for="mcq-skip-not">
                {{ "survey.new.mcq.skip-not" | translate  }}
            </label>
        </div>

        <div @inOut *ngIf="!skipMcq?.value">
            <!-- TIME AND MONEY -->
            <h6 class='text-uppercase fw-bold fst-italic text-center mt-4 mb-2'>{{ "survey.new.mcq.money-time.title" | translate }}</h6>
            <div class="text-secondary fst-italic my-3">{{ "survey.new.mcq.money-time.text-1" | translate }}.</div>

            <div>
                <div class="form-group row mb-0">
                    <span class="col-3"></span>
                    <p class="col-2 fw-bold">{{ "survey.new.mcq.money-time.immediate" | translate }}</p>
                    <p class="col-2 fw-bold">{{ "survey.new.mcq.money-time.deferred" | translate }}</p>
                    <p class="col-3 fw-bold">{{ "survey.new.mcq.money-time.delay" | translate }}</p>
                    <span class="col-2"></span>
                </div>
                <div *ngFor="let item of mcqList" class="row" style="line-height:1em">
                    <span class="col-3"></span>
                    <div class="col-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="{{item.id}}-1" formControlName="{{item.id}}" value="A">
                            <label class="form-check-label" for="{{item.id}}-1">€{{ item.immediate }}</label>
                        </div>
                    </div>
                    <div class="col-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" id="{{item.id}}-2" formControlName="{{item.id}}" value="B">
                            <label class="form-check-label" for="{{item.id}}-2">€{{ item.deferred }}</label>
                        </div>
                    </div>
                    <p class="col-3 ps-5">{{ item.delay }}</p>
                    <span class="col-2"></span>
                </div>
                <div class="form-group row mb-0" *ngIf="hasMcqError()">
                    <span class="col-3"></span>
                    <mat-error class="col-6">
                        {{ "validation.required" | translate }}
                    </mat-error>
                    <span class="col-3"></span>
                </div>
            </div>

            <!-- BEHAVIOR PREFERENCES -->
            <h6 class='text-uppercase fw-bold fst-italic text-center mt-4 mb-2'>{{ "survey.new.mcq.behavior-preferences.title" | translate }}</h6>
            <div class="text-secondary fst-italic my-3">{{ "survey.new.mcq.behavior-preferences.text-1" | translate }}.</div>

            <div>
                <div class="form-group row mb-0 mt-3">
                    <span class="col-2"></span>
                    <p class="col-3 fw-bold text-center">{{ "survey.new.mcq.behavior-preferences.lottery-a" | translate }}</p>
                    <span class="col-2"></span>
                    <p class="col-3 fw-bold text-center">{{ "survey.new.mcq.behavior-preferences.lottery-b" | translate }}</p>
                    <span class="col-2"></span>
                </div>
                <div class="row">
                    <span class="col-2"></span>
                    <p class="col-1 fw-bold text-end">€ {{ lotteryList.a.valueA }}</p>
                    <p class="col-1"></p>
                    <p class="col-1 fw-bold">€ {{ lotteryList.a.valueB }}</p>
                    <span class="col-2"></span>
                    <p class="col-1 fw-bold text-end">€ {{ lotteryList.b.valueA }}</p>
                    <p class="col-1"></p>
                    <p class="col-1 fw-bold">€ {{ lotteryList.b.valueB }}</p>
                    <span class="col-2"></span>
                </div>
                <div class="row" style="line-height:1em" *ngFor="let item of lotteryList.percentage">
                    <span class="col-2"></span>
                    <label class="col-1 text-end" for="{{item.id}}-A">{{ item.a }}%</label>
                    <p class="col-1"></p>
                    <label class="col-1" for="{{item.id}}-A">{{ item.b }}%</label>
                    <div class="col-1">
                        <div class="form-check">
                            <input class="form-check-input" id="{{item.id}}-A" formControlName="{{item.id}}" type="radio" value="{{ lotteryList.a.id }}">
                        </div>
                    </div>
                    <div class="col-1 text-end">
                        <div class="form-check">
                            <input class="form-check-input" id="{{item.id}}-B" formControlName="{{item.id}}" type="radio" value="{{ lotteryList.b.id }}">
                        </div>
                    </div>
                    <label class="col-1 text-end" for="{{item.id}}-B">{{ item.a }}%</label>
                    <p class="col-1"></p>
                    <label class="col-1" for="{{item.id}}-B">{{ item.b }}%</label>
                    <span class="col-2"></span>
                </div>

                <div class="form-group row mb-0" *ngIf="hasLotteryError()">
                    <span class="col-5"></span>
                    <mat-error class="col-7">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- NEXT -->
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="onSubmit()">
                {{ "survey.new.mcq.next" | translate }}
            </button>
        </div>
    </div>
</div>
