import { AbstractControl, ValidatorFn } from "@angular/forms";

export function checkVatCode(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const error: {[key: string]: any} = {incorrectFormat: true};

    if(!control.value) {
      return null;
    }

    if(! /^[0-9]{11}$/.test(control.value)) {
            return error;
    }
    
    var s = 0;
        for (let i=0; i<=9; i+=2) {
            s += control.value.charCodeAt(i) - '0'.charCodeAt(0);
    }
    
        for (let i=1; i<=9; i+=2 ) {
            let c = 2*(control.value.charCodeAt(i) - '0'.charCodeAt(0));
            if (c > 9) {
        c = c - 9;
      }

            s += c;
    }
    
        const controllo = (10 - s % 10) % 10;
        
        return controllo == control.value.charCodeAt(10) - '0'.charCodeAt(0) ? null : error;
  }
}

export function checkFiscalCode(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const error: {[key: string]: any} = {fiscalCodeFormat: true};

    if(!control.value) {
      return null;
    }

    var cf = control.value.toUpperCase();
        
        if(! /^[0-9A-Z]{16}$/.test(cf)) {
            return error;
    }
    
    var s = 0;
        var even_map = "BAFHJNPRTVCESULDGIMOQKWZYX";
        for(var i = 0; i < 15; i++) {
            var c = cf[i];
            var n = 0;
            
            if("0" <= c && c <= "9") {
                n = c.charCodeAt(0) - "0".charCodeAt(0);
            } else {
                n = c.charCodeAt(0) - "A".charCodeAt(0);
            }
            
            if((i & 1) === 0) {
                n = even_map.charCodeAt(n) - "A".charCodeAt(0);
            }
            
            s += n;
        }
        
        if(s%26 + "A".charCodeAt(0) !== cf.charCodeAt(15)) {
            return error;
        }
        
        return null;
  }
}

export function domainRequired(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    return control.value && control.value.id ? null : {required: true};
  }
}