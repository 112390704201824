import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { ExternalComponent } from './components/landing-page/survey/external/external.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [AuthGuard], children: [

  ]},
  { path: 'external', component: ExternalComponent },

  { path: '**', redirectTo: '/survey/search', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
