<div class="container my-3">
    <div class="text-secondary fst-italic">{{ "survey.new.savings-investments.text-1" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.savings-investments.text-2" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.savings-investments.text-3" | translate }}.</div>

    <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

    <div *ngIf="formGroup" [formGroup]="formGroup">
        <!-- GOALS -->
        <h6 class='text-uppercase fw-bold fst-italic text-center pb-1'>{{ "survey.new.savings-investments.goals.title" | translate }}</h6>
		
        <div>
            <!-- DURATION -->
            <div class="form-group row">
                <label for="duration" class="col-6 col-form-label">{{ "survey.new.savings-investments.goals.duration" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="duration" formControlName="duration">
                        <option value=""></option>
                        <option *ngFor="let key of ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49','50']" [value]="key">
                            {{ key }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(duration)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
    
            <!-- THIRD YEAR REVENUE -->
            <div class="form-group row">
                <label for="thirdYearRevenue" class="col-6 col-form-label">{{ "survey.new.savings-investments.goals.third-year" | translate }}</label>
                <div class="col-6">
                    <input suffix="%" mask="separator.0" thousandSeparator="." type="text" class="form-control" id="thirdYearRevenue" formControlName="thirdYearRevenue">
                </div>
            </div>
    
            <!-- MAX LOSS -->
            <div class="form-group row">
                <label for="maxLoss" class="col-6 col-form-label">{{ "survey.new.savings-investments.goals.max-loss" | translate }}</label>
                <div class="col-6">
                    <input suffix="%" mask="separator.0" thousandSeparator="." type="text" class="form-control" id="maxLoss" formControlName="maxLoss">
                </div>
            </div>
        </div>
        
        <!-- FINANCIAL KNOWLEDGE -->
        <h6 class='text-uppercase fw-bold fst-italic text-center mt-4 mb-2'>{{ "survey.new.savings-investments.financial-knowledge.title" | translate }}</h6>

        <div>
            <!-- SKIP KNOWLEDGE TEST -->
            <div class="form-check">
                <input class="form-check-input" type="radio" [value]="true" formControlName="skipFinancialKnowledge" id="financial-knowledge-skip">
                <label class="form-check-label" for="financial-knowledge-skip">
                    {{ "survey.new.savings-investments.financial-knowledge.skip" | translate: { class: defaultKnokledgeClass } }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" [value]="false" formControlName="skipFinancialKnowledge" id="financial-knowledge-skip-not">
                <label class="form-check-label" for="financial-knowledge-skip-not">
                    {{ "survey.new.savings-investments.financial-knowledge.skip-not" | translate  }}
                </label>
            </div>

            <!-- RANDOM QUESTIONS -->
            <div @inOut *ngIf="!skipFinancialKnowledge?.value">
                <div class="text-secondary fst-italic my-3">{{ "survey.new.savings-investments.financial-knowledge.text-1" | translate }}.</div>

                <div class="form-group row" *ngFor="let question of financialKnowledgeQuestionList">
                    <label for="{{question.id}}" class="col-6 col-form-label">{{ question.text }}</label>
                    <div class="col-6">
                        <select class="form-select" id="{{ question.id }}" formControlName="{{ question.id }}">
                            <option value=""></option>
                            <option *ngFor="let answer of question.answers" [value]="answer.id">
                                {{ answer.text }}
                            </option>
                        </select>
                        <mat-error *ngIf="hasQuestionError(question.id)">
                            {{ "validation.required" | translate }}
                        </mat-error>
                    </div>
                </div>
            </div>
        </div>

        <!-- FINANCIAL EXPERIENCE -->
        <h6 class='text-uppercase fw-bold fst-italic text-center mt-4 mb-2'>{{ "survey.new.savings-investments.financial-experience.title" | translate }}</h6>
        <div class="text-secondary fst-italic mt-3">{{ "survey.new.savings-investments.financial-experience.text-1" | translate }}</div>
        <div class="text-secondary fst-italic mb-3">{{ "survey.new.savings-investments.financial-experience.text-2" | translate }}.</div>

        <div>
            <!-- STOCKS -->
            <div class="form-group row">
                <label for="stocks" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.stocks" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="stocks" formControlName="stocks">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(stocks)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- BONDS -->
            <div class="form-group row">
                <label for="bonds" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.bonds" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="bonds" formControlName="bonds">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(bonds)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- INVESTMENT FUNDS -->
            <div class="form-group row">
                <label for="investmentFunds" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.investment-funds" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="investmentFunds" formControlName="investmentFunds">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(investmentFunds)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- FINANCIAL POLICIES -->
            <div class="form-group row">
                <label for="financialPolicies" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.financial-policies" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="financialPolicies" formControlName="financialPolicies">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(financialPolicies)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- ASSET MANAGEMENT -->
            <div class="form-group row">
                <label for="assetManagement" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.asset-management" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="assetManagement" formControlName="assetManagement">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(assetManagement)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- DERIVATES -->
            <div class="form-group row">
                <label for="derivates" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.derivates" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="derivates" formControlName="derivates">
                        <option value=""></option>
                        <option *ngFor="let key of ['Y', 'N']" [value]="key">
                            {{ "survey.domain.optional." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(derivates)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>

            <!-- OPERATION NUMBER -->
            <div class="form-group row">
                <label for="operationNumber" class="col-6 col-form-label">{{ "survey.new.savings-investments.financial-experience.operation-number" | translate }}</label>
                <div class="col-6">
                    <select class="form-select" id="operationNumber" formControlName="operationNumber">
                        <option value=""></option>
                        <option *ngFor="let key of ['NO1', 'NO2', 'NO3', 'NO4']" [value]="key">
                            {{ "survey.domain.savings-investments." + key | translate }}
                        </option>
                    </select>
                    <mat-error *ngIf="hasError(operationNumber)">
                        {{ "validation.required" | translate }}
                    </mat-error>
                </div>
            </div>
        </div>

        <!-- NEXT -->
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="onSubmit()">
                {{ "survey.new.savings-investments.next" | translate }}
            </button>
        </div>
    </div>
</div>
