<div class="container my-3">
    <div class="text-secondary fst-italic">{{ "survey.new.bis-bas.text-1" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.bis-bas.text-2" | translate }}.</div>

    <div *ngIf="formGroup" [formGroup]="formGroup">
        <!-- SKIP -->
        <div class="text-secondary fst-italic mt-3">{{ "survey.new.bis-bas.text-3" | translate }}.</div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="skipBisBas()">
                {{ "survey.new.bis-bas.skip" | translate }}
            </button>
        </div>

        <div class="my-4" style="border-bottom: 1px solid #dee2e6"></div>

        <!-- BIS 1 -->
        <div class="form-group row">
            <label for="bis1" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis1" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis1" formControlName="bis1">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis1)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 2 -->
        <div class="form-group row">
            <label for="bis2" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis2" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis2" formControlName="bis2">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis2)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 3 -->
        <div class="form-group row">
            <label for="bis3" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis3" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis3" formControlName="bis3">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis3)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 4 -->
        <div class="form-group row">
            <label for="bis4" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis4" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis4" formControlName="bis4">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis4)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 5 -->
        <div class="form-group row">
            <label for="bis5" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis5" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis5" formControlName="bis5">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis5)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 6 -->
        <div class="form-group row">
            <label for="bis6" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis6" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis6" formControlName="bis6">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis6)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BIS 7 -->
        <div class="form-group row">
            <label for="bis7" class="col-6 col-form-label">{{ "survey.new.bis-bas.bis7" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bis7" formControlName="bis7">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bis7)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAR 1 -->
        <div class="form-group row">
            <label for="bar1" class="col-6 col-form-label">{{ "survey.new.bis-bas.bar1" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bar1" formControlName="bar1">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bar1)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAR 2 -->
        <div class="form-group row">
            <label for="bar2" class="col-6 col-form-label">{{ "survey.new.bis-bas.bar2" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bar2" formControlName="bar2">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bar2)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAR 3 -->
        <div class="form-group row">
            <label for="bar3" class="col-6 col-form-label">{{ "survey.new.bis-bas.bar3" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bar3" formControlName="bar3">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bar3)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAR 4 -->
        <div class="form-group row">
            <label for="bar4" class="col-6 col-form-label">{{ "survey.new.bis-bas.bar4" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bar4" formControlName="bar4">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bar4)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAR 5 -->
        <div class="form-group row">
            <label for="bar5" class="col-6 col-form-label">{{ "survey.new.bis-bas.bar5" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bar5" formControlName="bar5">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bar5)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAD 1 -->
        <div class="form-group row">
            <label for="bad1" class="col-6 col-form-label">{{ "survey.new.bis-bas.bad1" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bad1" formControlName="bad1">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bad1)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAD 2 -->
        <div class="form-group row">
            <label for="bad2" class="col-6 col-form-label">{{ "survey.new.bis-bas.bad2" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bad2" formControlName="bad2">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bad2)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAD 3 -->
        <div class="form-group row">
            <label for="bad3" class="col-6 col-form-label">{{ "survey.new.bis-bas.bad3" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bad3" formControlName="bad3">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bad3)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAD 4 -->
        <div class="form-group row">
            <label for="bad4" class="col-6 col-form-label">{{ "survey.new.bis-bas.bad4" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="bad4" formControlName="bad4">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(bad4)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAF 1 -->
        <div class="form-group row">
            <label for="baf1" class="col-6 col-form-label">{{ "survey.new.bis-bas.baf1" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="baf1" formControlName="baf1">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(baf1)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAF 2 -->
        <div class="form-group row">
            <label for="baf2" class="col-6 col-form-label">{{ "survey.new.bis-bas.baf2" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="baf2" formControlName="baf2">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(baf2)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAF 3 -->
        <div class="form-group row">
            <label for="baf3" class="col-6 col-form-label">{{ "survey.new.bis-bas.baf3" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="baf3" formControlName="baf3">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(baf3)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- BAF 4 -->
        <div class="form-group row">
            <label for="baf4" class="col-6 col-form-label">{{ "survey.new.bis-bas.baf4" | translate }}</label>
            <div class="col-6">
                <select class="form-select" id="baf4" formControlName="baf4">
                    <option value=""></option>
                    <option *ngFor="let key of ['BS1', 'BS2', 'BS3', 'BS4']" [value]="key">
                        {{ "survey.domain.bis-bas." + key | translate }}
                    </option>
                </select>
                <mat-error *ngIf="hasError(baf4)">
                    {{ "validation.required" | translate }}
                </mat-error>
            </div>
        </div>

        <!-- NEXT -->
        <div class="d-flex justify-content-end">
            <button class="btn btn-primary mt-3 next-step" [disabled]="formGroup.disabled" (click)="onSubmit()">
                {{ "survey.new.ndg-data.next" | translate }}
            </button>
        </div>
    </div>
</div>
