import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewComponent } from './new.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { NdgDataComponent } from './ndg-data/ndg-data.component';
import { AnagraphicDataComponent } from './anagraphic-data/anagraphic-data.component';
import { NgbDatepickerModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { UppercaseDirective } from 'src/app/directives/uppercase.directive';
import { NgxMaskModule } from 'ngx-mask';
import { BisBasComponent } from './bis-bas/bis-bas.component';
import { SavingsInvestmentsComponent } from './savings-investments/savings-investments.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { McqComponent } from './mcq/mcq.component';
import { SavingsComponent } from './savings/savings.component';
import { ProtectionPersonComponent } from './protection-person/protection-person.component';
import { ProtectionGoodsComponent } from './protection-goods/protection-goods.component';

@NgModule({
  declarations: [
    NewComponent,
    NdgDataComponent,
    AnagraphicDataComponent,
    UppercaseDirective,
    BisBasComponent,
    SavingsInvestmentsComponent,
    McqComponent,
    SavingsComponent,
    ProtectionPersonComponent,
    ProtectionGoodsComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,

    MatTabsModule,
    MatInputModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatRadioModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,

    NgbDatepickerModule,
    NgbTypeaheadModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    NewComponent
  ]
})
export class NewModule { }
