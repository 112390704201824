import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'savings',
  templateUrl: './savings.component.html'
})
export class SavingsComponent {
  @Input() value?: string;
  @Output() complete = new EventEmitter<void>();

  completed: boolean = false;

  getAmount() {
    return this.value || "0,00";
  }

  onSubmit() {
    this.completed = true;
    this.complete.emit();
  }
}
