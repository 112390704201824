import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BisBas } from '../model/survey.model';

@Component({
  selector: 'bis-bas',
  templateUrl: './bis-bas.component.html'
})
export class BisBasComponent implements OnInit {
  @Output() complete = new EventEmitter<BisBas>();

  formGroup!: FormGroup;

  constructor() {}

  ngOnInit() {
    this.formGroup = new FormGroup({
      bis1: new FormControl(null, Validators.required),
      bis2: new FormControl(null, Validators.required),
      bis3: new FormControl(null, Validators.required),
      bis4: new FormControl(null, Validators.required),
      bis5: new FormControl(null, Validators.required),
      bis6: new FormControl(null, Validators.required),
      bis7: new FormControl(null, Validators.required),
      
      bar1: new FormControl(null, Validators.required),
      bar2: new FormControl(null, Validators.required),
      bar3: new FormControl(null, Validators.required),
      bar4: new FormControl(null, Validators.required),
      bar5: new FormControl(null, Validators.required),

      bad1: new FormControl(null, Validators.required),
      bad2: new FormControl(null, Validators.required),
      bad3: new FormControl(null, Validators.required),
      bad4: new FormControl(null, Validators.required),

      baf1: new FormControl(null, Validators.required),
      baf2: new FormControl(null, Validators.required),
      baf3: new FormControl(null, Validators.required),
      baf4: new FormControl(null, Validators.required),
    });
  }

  get bis1() { return this.formGroup.get('bis1'); }
  get bis2() { return this.formGroup.get('bis2'); }
  get bis3() { return this.formGroup.get('bis3'); }
  get bis4() { return this.formGroup.get('bis4'); }
  get bis5() { return this.formGroup.get('bis5'); }
  get bis6() { return this.formGroup.get('bis6'); }
  get bis7() { return this.formGroup.get('bis7'); }

  get bar1() { return this.formGroup.get('bar1'); }
  get bar2() { return this.formGroup.get('bar2'); }
  get bar3() { return this.formGroup.get('bar3'); }
  get bar4() { return this.formGroup.get('bar4'); }
  get bar5() { return this.formGroup.get('bar5'); }

  get bad1() { return this.formGroup.get('bad1'); }
  get bad2() { return this.formGroup.get('bad2'); }
  get bad3() { return this.formGroup.get('bad3'); }
  get bad4() { return this.formGroup.get('bad4'); }

  get baf1() { return this.formGroup.get('baf1'); }
  get baf2() { return this.formGroup.get('baf2'); }
  get baf3() { return this.formGroup.get('baf3'); }
  get baf4() { return this.formGroup.get('baf4'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  skipBisBas() {
    this.onSubmit(true);

    this.bis1?.setValue(null);
    this.bis2?.setValue(null);
    this.bis3?.setValue(null);
    this.bis4?.setValue(null);
    this.bis5?.setValue(null);
    this.bis6?.setValue(null);
    this.bis7?.setValue(null);

    this.bar1?.setValue(null);
    this.bar2?.setValue(null);
    this.bar3?.setValue(null);
    this.bar4?.setValue(null);
    this.bar5?.setValue(null);

    this.bad1?.setValue(null);
    this.bad2?.setValue(null);
    this.bad3?.setValue(null);
    this.bad4?.setValue(null);

    this.baf1?.setValue(null);
    this.baf2?.setValue(null);
    this.baf3?.setValue(null);
    this.baf4?.setValue(null);
  }

  onSubmit(skip: boolean = false) {
    if(!skip && !this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.formGroup.disable();

    let out: BisBas = {
      skipBisBas: skip,
      
      bis1: this.bis1?.value,
      bis2: this.bis2?.value,
      bis3: this.bis3?.value,
      bis4: this.bis4?.value,
      bis5: this.bis5?.value,
      bis6: this.bis6?.value,
      bis7: this.bis7?.value,

      bar1: this.bar1?.value,
      bar2: this.bar2?.value,
      bar3: this.bar3?.value,
      bar4: this.bar4?.value,
      bar5: this.bar5?.value,

      bad1: this.bad1?.value,
      bad2: this.bad2?.value,
      bad3: this.bad3?.value,
      bad4: this.bad4?.value,

      baf1: this.baf1?.value,
      baf2: this.baf2?.value,
      baf3: this.baf3?.value,
      baf4: this.baf4?.value,
    };

    this.complete.emit(out);
  }
}
