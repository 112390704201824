import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FinancialKnowledgeQuestionResponse, SurveyForListResponse, SurveyPart1ChecksResponse, SurveyPart1Request, SurveyPart2Request } from '../new/model/survey.model';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  constructor(private http: HttpClient) {}

  insertSurvetPart1(request: SurveyPart1Request): Observable<SurveyPart1ChecksResponse> {
    return this.http.post<SurveyPart1ChecksResponse>(environment.api.survey + '/part1', request);
  }

  getRandomFinancialKnowledgeQuestions(): Observable<FinancialKnowledgeQuestionResponse[]> {
    return this.http.get<FinancialKnowledgeQuestionResponse[]>(environment.api.survey + '/financial-knowledge');
  }

  insertSurvetPart2(surveyId: string, request: SurveyPart2Request): Observable<SurveyForListResponse> {
    return this.http.post<SurveyForListResponse>(environment.api.survey + '/' + surveyId + '/part2', request);
  }
}
