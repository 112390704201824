import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProtectionGoods } from '../model/survey.model';

@Component({
  selector: 'protection-goods',
  templateUrl: './protection-goods.component.html',
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms ease-in',
          style({ opacity: 1 })
        )
      ]),
      transition('* => void', [
        animate('500ms ease-out',
          style({ opacity: 0 })
        ) 
      ])
    ]) 
  ]
})
export class ProtectionGoodsComponent implements OnInit {
  @Input() protectionHouse?: boolean;
  @Input() protectionHouseSeismic?: boolean;
  @Input() seismicClass?: string;
  @Input() seismicityValue?: string;

  @Output() complete = new EventEmitter<ProtectionGoods>();

  formGroup!: FormGroup;

  ngOnInit() {
    this.formGroup = new FormGroup({
      company: new FormControl(null),
      flagAdvice: new FormControl(null, this.getValidators(!this.protectionHouse)),
      
      houseType: new FormControl(null, this.getValidators(this.protectionHouse)),
      houseDimension: new FormControl(null, this.getValidators(this.protectionHouse)),
      houseValue: new FormControl(0, this.getValidators(this.protectionHouse)),
      houseProtection: new FormControl(null, this.getValidators(this.protectionHouse)),
      otherProperties: new FormControl(null, this.getValidators(this.protectionHouse)),
      houseSismology: new FormControl(null, this.getValidators(this.protectionHouse && this.protectionHouseSeismic))
    });

    if(this.protectionHouse) {
      this.otherProperties?.valueChanges.subscribe({
        next: (value: string) => {
          if (value === 'Y') {
            this.formGroup.addControl('houseUse', new FormControl(null, Validators.required));
          } else {
            this.formGroup?.removeControl('houseUse');
          }
        }
      });
    }
  }

  getValidators(required: boolean | undefined) {
    return required ? [Validators.required] : []
  }

  get company() { return this.formGroup.get('company'); }
  get flagAdvice() { return this.formGroup.get('flagAdvice'); }
  get houseType() { return this.formGroup.get('houseType'); }
  get houseDimension() { return this.formGroup.get('houseDimension'); }
  get houseValue() { return this.formGroup.get('houseValue'); }
  get houseProtection() { return this.formGroup.get('houseProtection'); }
  get otherProperties() { return this.formGroup.get('otherProperties'); }
  get houseUse() { return this.formGroup.get('houseUse'); }
  get houseSismology() { return this.formGroup.get('houseSismology'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  onSubmit() {
    if(!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let output: ProtectionGoods = {
      company: this.company?.value,
      flagAdvice: this.flagAdvice?.value,
      houseType: this.houseType?.value,
      houseDimension: this.houseDimension?.value,
      houseValue: this.houseValue?.value,
      houseProtection: this.houseProtection?.value,
      otherProperties: this.otherProperties?.value,
      houseUse: this.houseUse?.value,
      houseSismology: this.houseSismology?.value
    };

    this.formGroup.disable();
    this.complete.emit(output);
  }
}
