<div class="container my-3">
    <div class="text-secondary fst-italic">{{ "survey.new.savings.text-1" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.savings.text-2" | translate }}.</div>
    <div class="text-secondary fst-italic">{{ "survey.new.savings.text-3" | translate: { amount: getAmount() } }}.</div>

    <!-- NEXT -->
    <div class="d-flex justify-content-end">
        <button class="btn btn-primary mt-3 next-step" [disabled]="completed" (click)="onSubmit()">
            {{ "survey.new.savings.next" | translate }}
        </button>
    </div>
</div>
