import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { NdgResponse } from 'src/app/model/ndg';
import { MessageService } from 'src/app/service/message.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { SurveyService } from '../service/survey.service';
import { BisBas, MCQ, NdgData, ProtectionGoods, ProtectionPerson, SavingsInvestments, SurveyForListResponse, SurveyPart1ChecksResponse, SurveyPart1Request, SurveyPart2Request } from './model/survey.model';

@Component({
  selector: 'new-survey',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent {
  @Input() ndg!: NdgResponse;
  @Input() external: boolean = false;
  @Output() completed = new EventEmitter<SurveyForListResponse>();

  formGroup = new FormGroup({});

  selectedTab: number = 0;

  showAnagraphicTab: boolean = false;
  surveyPart1Request: SurveyPart1Request = new SurveyPart1Request();
  surveyPart2Request: SurveyPart2Request = new SurveyPart2Request();
  surveyPart1Response?: SurveyPart1ChecksResponse;

  constructor(
    private elementRef: ElementRef,
    private spinnerService: SpinnerService,
    private surveyService: SurveyService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.showAnagraphicTab = !this.ndg?.name;
  }
  
  onSelectedTabChange(event: MatTabChangeEvent): void {
    event.tab.disabled = false;
  }

  onAnagraphicDataComplete(ndg: NdgResponse) {
    this.ndg = ndg;
    this.selectedTab++;
  }

  onNdgDataComplete(ndgData: NdgData) {
    this.surveyPart1Request.ndgData = ndgData;
    this.selectedTab++;
  }

  onBisBasComplete(bisBas: BisBas) {
    this.surveyPart1Request.bisBas = bisBas;

    this.spinnerService.showProgress(this.elementRef);

    this.surveyService.insertSurvetPart1(this.surveyPart1Request).subscribe({
      next: (response: SurveyPart1ChecksResponse) => {
        this.surveyPart1Response = response;

        this.spinnerService.detach(this.elementRef);
        this.selectedTab++;
      },
      error: () => {
        this.spinnerService.detach(this.elementRef);
        this.messageService.error("survey.new.message.unknown-error");
      }
    });
  }

  onSavingsInvestmentsComplete(savingInvestments: SavingsInvestments) {
    this.surveyPart2Request.savingsInvestments = savingInvestments;
    this.selectedTab++;
  }

  onMCQComplete(mcq: MCQ) {
    this.surveyPart2Request.mcq = mcq;
    this.selectedTab++;
  }

  onSavingsComplete() {
    this.selectedTab++;
  }

  onProtectionGoodsComplete(protectionGoods: ProtectionGoods) {
    this.surveyPart2Request.protectionGoods = protectionGoods;
    this.selectedTab++;
  }

  onProtectionPersonComplete(protectionPerson: ProtectionPerson) {
    this.surveyPart2Request.protectionPerson = protectionPerson;
    this.completeSurvey();
  }

  private completeSurvey() {
    this.spinnerService.showProgress(this.elementRef);
    console.log(this.surveyPart2Request);

    this.surveyService.insertSurvetPart2(this.surveyPart1Response!.surveyId, this.surveyPart2Request).subscribe({
      next: (response: SurveyForListResponse) => {
        this.spinnerService.detach(this.elementRef);
        this.afterSurveyCompleted(response);
      },
      error: () => {
        this.spinnerService.detach(this.elementRef);
        this.messageService.error("survey.new.message.unknown-error");
      }
    });
  }

  private afterSurveyCompleted(survey: SurveyForListResponse) {
    this.messageService.success('survey.new.message.success', { surveyId: this.surveyPart1Response?.surveyId });
    this.completed.emit(survey);

    if(!this.external) {
      // Notify observable to update survey list
      //this.surveyService.$newSurvey.next({surveyId: surveyInfo.surveyId, insertTime: surveyInfo.insertTime});

      // Route to new created survey
      this.router.navigate(['../' + this.surveyPart1Response?.surveyId], {relativeTo: this.route});
    }
  }
}
