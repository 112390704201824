import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable } from 'rxjs';
import { Auth } from '../model/auth';
import { Permission, Token } from '../model/token';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<Auth | null>;
  private ssoTokenSubject: BehaviorSubject<string | null>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<Auth | null>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.ssoTokenSubject = new BehaviorSubject<string | null>(null);
  }
  
  public get currentUserValue(): Auth | null {
    return this.currentUserSubject.value;
  }
  
  public get ssoTokenValue(): string | null {
    return this.ssoTokenSubject?.value;
  }

  public isLoggedIn(): boolean {
    const currentUser = this.currentUserValue;
    return !!currentUser && !!currentUser.token;
  }

  public hasAuthority(authority: Permission): boolean {
    if(this.isLoggedIn()) {
      const tokenPayload: Token = jwt_decode(JSON.stringify(this.currentUserValue?.token));
      return tokenPayload.auth.indexOf(authority) > -1;
    }
    
    return false;
  }

  nextToken(token: string): void {
    this.ssoTokenSubject.next(token);
  }

  authenticate(username: string, password: string): Observable<Auth> {
    return this.http.post<Auth>(environment.api.auth, { username, password })
    .pipe(
      map((data: Auth) => {
          if (data.token !== null) {
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.currentUserSubject.next(data);
          }

          return data;
        }
      )
    );
  }

  authenticateExternal(email: string): Observable<Auth> {
    return this.http.post<Auth>(environment.api.auth + '/external', email)
    .pipe(
      map((data: Auth) => {
        this.ssoTokenSubject.next(null);
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.currentUserSubject.next(data);

        return data;
      }),
      catchError(
        (e: any) => {
          this.ssoTokenSubject.next(null);
          throw e;
        }
      )
    );
  }

  /*resetPassword(usernameOrEmail: string): Observable<void> {
      return this.http.post<void>(environment.api.public + '/reset-password', { usernameOrEmail });
  }

  changePassword(username: string, password: string, newPassword: string): Observable<void> {
      return this.http.post<void>(environment.api.public + '/change-password', { username, password, newPassword });
  }*/

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
