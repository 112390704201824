import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Observable } from 'rxjs';
import { NdgRequest, NdgResponse } from 'src/app/model/ndg';
import { City } from 'src/app/model/property';
import { DomainService } from 'src/app/service/domain.service';
import { MessageService } from 'src/app/service/message.service';
import { NdgService } from 'src/app/service/ndg.service';
import { SpinnerService } from 'src/app/service/spinner.service';
import { domainRequired, checkFiscalCode } from 'src/app/validators/validators';

@Component({
  selector: 'anagraphic-data',
  templateUrl: './anagraphic-data.component.html'
})
export class AnagraphicDataComponent {
  @Input() ndg!: NdgResponse;
  @Output() complete = new EventEmitter<NdgResponse>();

  formGroup!: FormGroup;
  completed: boolean = false;
  loading: boolean = false;

  cityList: City[] = [];

  cityFormatter = (city: City) => city?.id ? city.description + ' (' + city.districtId + ')' : '';
  citySearch = (text$: Observable<string>) => text$.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    map(term => this.cityList.filter(city => new RegExp(term.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1"), 'mi').test(city.description)).slice(0, 50))
  );

  constructor(
    private domainService: DomainService,
    private spinnerService: SpinnerService,
    private elementRef: ElementRef,
    private ndgService: NdgService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.spinnerService.showProgress(this.elementRef);

    this.domainService.getCityList().subscribe({
      next: (data: City[]) => {
        this.cityList = data;
        this.spinnerService.detach(this.elementRef);
        this.initFormGroup();
      },
      error: () => {
        this.spinnerService.detach(this.elementRef);
      }
    });
  }

  initFormGroup() {
    this.formGroup = new FormGroup({
      email: new FormControl(this.ndg?.email, Validators.email),
      name: new FormControl(this.ndg?.name, Validators.required),
      surname: new FormControl(this.ndg?.surname, Validators.required),
      address: new FormControl(this.ndg?.address, Validators.required),
      city: new FormControl(this.getCityFromId(this.ndg?.city), domainRequired()),
      postalCode: new FormControl(this.ndg?.postalCode, [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
      fiscalCode: new FormControl(this.ndg?.fiscalCode, [Validators.required, checkFiscalCode()]),
      phone: new FormControl(this.ndg?.phone)
    });

    if(this.ndg.name) {
      this.formGroup.disable();
      this.completed = true;
    } else {
      this.email?.disable();
    }
  }

  get email() { return this.formGroup.get('email'); }
  get name() { return this.formGroup.get('name'); }
  get surname() { return this.formGroup.get('surname'); }
  get address() { return this.formGroup.get('address'); }
  get postalCode() { return this.formGroup.get('postalCode'); }
  get city() { return this.formGroup.get('city'); }
  get fiscalCode() { return this.formGroup.get('fiscalCode'); }
  get phone() { return this.formGroup.get('phone'); }

  hasError(field: AbstractControl | null) {
    return field?.touched && !field.disabled && !field.valid;
  }

  getError(field: AbstractControl | null) {
    return field?.errors && Object.keys(field?.errors)[0];
  }
  
  onCitySelected(selectedCity: City): void {
    if(this.cityList.indexOf(selectedCity) > -1) {
      this.city?.setValue(selectedCity);
      this.postalCode?.setValue(selectedCity.cap.replace(/x/g, ''));
    }
  }

  getCityFromId(id: string): City | undefined {
    return this.cityList.find(c => c.id === id);
  }

  onSubmit() {
    if(!this.formGroup.valid || this.completed) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.completed = true;

    const payload: NdgRequest = {
      name: this.name?.value,
      surname: this.surname?.value,
      address: this.address?.value,
      postalCode: this.postalCode?.value,
      city: this.city?.value?.id,
      fiscalCode: this.fiscalCode?.value,
      phone: this.phone?.value,
    };
    
    this.ndgService.updateNdg(this.ndg.id, payload).subscribe({
      next: (ndg: NdgResponse) => {
        this.complete.emit(ndg);
        this.loading = false;
      },
      error: () => {
        this.messageService.error('survey.new.message.unknown-error');

        this.loading = false;
        this.completed = false;
      }
    });
  }
}
