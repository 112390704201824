import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MCQ } from '../model/survey.model';

@Component({
  selector: 'mcq',
  templateUrl: './mcq.component.html',
  animations: [
    trigger('inOut', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms ease-in',
          style({ opacity: 1 })
        )
      ]),
      transition('* => void', [
        animate('500ms ease-out',
          style({ opacity: 0 })
        ) 
      ])
    ]) 
  ]
})
export class McqComponent {
  @Output() complete = new EventEmitter<MCQ>();

  formGroup!: FormGroup;
  defaultMcqClass: number = 0; //FIXME legacy non è impostata

  mcqList = mcqList;
  lotteryList = lotteryList;

  constructor() {
    this.formGroup = new FormGroup({
      skipMcq: new FormControl(true)
    });

    this.skipMcq?.valueChanges.subscribe({
      next: (skip: boolean) => {
        if (skip) {
          this.mcqList.forEach(item => {
            this.formGroup.removeControl(item.id);
          });

          this.lotteryList.percentage.forEach(item => {
            this.formGroup.removeControl(item.id);
          });
        } else {
          this.mcqList.forEach(item => {
            this.formGroup.addControl(item.id, new FormControl(null, Validators.required));
          });

          this.lotteryList.percentage.forEach(item => {
            this.formGroup.addControl(item.id, new FormControl(null, Validators.required));
          });
        }
      }
    });
  }

  get skipMcq() { return this.formGroup.get('skipMcq'); }

  hasError(field: AbstractControl | null) {
    return !!field && field.touched && !field.disabled && !field.valid;
  }

  hasMcqError() {
    return this.mcqList
        .map(item => this.hasError(this.formGroup.get(item.id)))
        .reduce((res, cur) => res || cur, false);
  }

  hasLotteryError() {
    return this.lotteryList.percentage
        .map(item => this.hasError(this.formGroup.get(item.id)))
        .reduce((res, cur) => res || cur, false);
  }

  onSubmit() {
    if(!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    let out: MCQ = {
      skipMcq: this.skipMcq?.value,

      mcq01: this.formGroup.get('MCQ01')?.value,
      mcq02: this.formGroup.get('MCQ02')?.value,
      mcq03: this.formGroup.get('MCQ03')?.value,
      mcq04: this.formGroup.get('MCQ04')?.value,
      mcq05: this.formGroup.get('MCQ05')?.value,
      mcq06: this.formGroup.get('MCQ06')?.value,
      mcq07: this.formGroup.get('MCQ07')?.value,
      mcq08: this.formGroup.get('MCQ08')?.value,
      mcq09: this.formGroup.get('MCQ09')?.value,
      mcq10: this.formGroup.get('MCQ10')?.value,
      mcq11: this.formGroup.get('MCQ11')?.value,
      mcq12: this.formGroup.get('MCQ12')?.value,
      mcq13: this.formGroup.get('MCQ13')?.value,
      mcq14: this.formGroup.get('MCQ14')?.value,
      mcq15: this.formGroup.get('MCQ15')?.value,

      lot01: this.formGroup.get('LOT01')?.value,
      lot02: this.formGroup.get('LOT02')?.value,
      lot03: this.formGroup.get('LOT03')?.value,
      lot04: this.formGroup.get('LOT04')?.value,
      lot05: this.formGroup.get('LOT05')?.value,
      lot06: this.formGroup.get('LOT06')?.value,
      lot07: this.formGroup.get('LOT07')?.value,
      lot08: this.formGroup.get('LOT08')?.value,
      lot09: this.formGroup.get('LOT09')?.value,
      lot10: this.formGroup.get('LOT10')?.value
    }
    
    this.formGroup.disable();
    this.complete.emit(out);
  }
}

interface MCQItem {
  id: string;
  immediate: number;
  deferred: number;
  delay: number;
}

let mcqList: MCQItem[] = [
  {id: "MCQ01", immediate: 54, deferred: 55, delay: 117},
  {id: "MCQ02", immediate: 31, deferred: 85, delay: 7},
  {id: "MCQ03", immediate: 14, deferred: 25, delay: 19},
  {id: "MCQ04", immediate: 78, deferred: 80, delay: 162},
  {id: "MCQ05", immediate: 11, deferred: 30, delay: 7},
  {id: "MCQ06", immediate: 67, deferred: 75, delay: 119},
  {id: "MCQ07", immediate: 34, deferred: 35, delay: 186},
  {id: "MCQ08", immediate: 27, deferred: 50, delay: 21},
  {id: "MCQ09", immediate: 69, deferred: 85, delay: 91},
  {id: "MCQ10", immediate: 49, deferred: 60, delay: 89},
  {id: "MCQ11", immediate: 25, deferred: 30, delay: 80},
  {id: "MCQ12", immediate: 41, deferred: 75, delay: 20},
  {id: "MCQ13", immediate: 54, deferred: 60, delay: 111},
  {id: "MCQ14", immediate: 22, deferred: 25, delay: 136},
  {id: "MCQ15", immediate: 20, deferred: 55, delay: 7}
];

interface LotteryItem {
  id: string;
  a: number;
  b: number;
}

interface LotteryChoice {
  id: string;
  valueA: string;
  valueB: string;
}

let lotteryList: {a: LotteryChoice, b: LotteryChoice, percentage: LotteryItem[]} = {
  a: {id: "A", valueA: "4,00", valueB: "3,20"},
  b: {id: "B", valueA: "7,00", valueB: "0,20"},
  percentage: [
    {id: "LOT01", a: 10, b: 90},
    {id: "LOT02", a: 20, b: 80},
    {id: "LOT03", a: 30, b: 70},
    {id: "LOT04", a: 40, b: 60},
    {id: "LOT05", a: 50, b: 50},
    {id: "LOT06", a: 60, b: 40},
    {id: "LOT07", a: 70, b: 30},
    {id: "LOT08", a: 80, b: 20},
    {id: "LOT09", a: 90, b: 10},
    {id: "LOT10", a: 100, b: 0},
  ]
}