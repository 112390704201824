import { ElementRef, Injectable } from '@angular/core';
import { DynamicOverlay } from '../spinner/dynamic-overlay.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private overlayRef = new Map();

  constructor(
    private dynamicOverlay: DynamicOverlay
  ) {}

  public showProgress(elRef: ElementRef): void {
    if (elRef && !this.overlayRef.has(elRef)) {
      this.dynamicOverlay.setContainerElement(elRef.nativeElement || elRef);

      const positionStrategy = this.dynamicOverlay
        .position()
        .global()
        .centerHorizontally()
        .centerVertically();

      this.overlayRef.set(elRef, this.dynamicOverlay.create({
        positionStrategy: positionStrategy,
        hasBackdrop: true
      }));

      this.overlayRef.get(elRef).attach(new ComponentPortal(SpinnerComponent));
    }
  }

  detach(elRef: ElementRef): void {
    if (this.overlayRef.has(elRef)) {
      this.overlayRef.get(elRef).detach();
      this.overlayRef.delete(elRef);
    }
  }
}
